<div class="modal-content">
  <div class="modal-header mb-n1">
    <span aria-hidden="true"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close"
        (click)="modalRef.hide()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <div class="mb-0"><strong>Vehicle Inspection Document</strong></div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center mb-4">
        <span *ngIf="brandInfo !== 'Lambo'">Upload or take a picture</span>
        <span *ngIf="brandInfo === 'Lambo'">Upload Selezione Program checklist</span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="center">

          <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" accept=".pdf, .jpeg, .jpg, .png, .jfif"
            (onFileLeave)="fileLeave($event)">

            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div class="text-center upload-icon">
                <i class="fas fa-cloud-upload-alt" aria-hidden="true"></i>
              </div>
              <span>Drag and Drop or&nbsp;</span>
              <a class="choose-file" (click)="openFileSelector()">Choose file</a>
              <span>&nbsp;to upload .pdf .png or .jpg.</span>
            </ng-template>
          </ngx-file-drop>

          <div class="upload-table" *ngIf="files.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th>File(s)</th>
                </tr>
              </thead>
              <tbody class="upload-name-style">
                <tr *ngFor="let item of files; let i=index">
                  <td><strong>{{ item.relativePath }}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center mt-3 mb-3">
        <button [disabled]="files.length == 0" class="btn primary-btn waves-light uppercase" mdbBtn mdbWavesEffect (click)="uploadFiles(modalRef)">Upload</button>
      </div>
    </div>
    <div class="row" *ngIf="brandInfo === 'Lambo' && files.length > 0">
      <div class="col-md-12 mb-4 text-left">
        <span>By submitting this document, I confirm that the vehicle has passed the Lamborghini Selezione checklist and is eligible for Vehicle Service Protection.</span>
      </div>
    </div>
    <div class="row" *ngIf="brandInfo === 'Porsche' && files.length > 0">
      <div class="col-md-12 mb-4 text-left">
        <span>By submitting this document, I confirm that the vehicle has passed the Porsche inspection checklist and is eligible for Vehicle Service Protection.</span>
      </div>
    </div>
  </div>
</div>