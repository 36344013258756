import { createReducer, on } from '@ngrx/store';
import { getDiscount, getDiscountFailed, getDiscountSuccess, fullPayFive, reset, loadInitState } from '../_actions/discount.actions';

export const initialState = null;


const _discountReducer = createReducer(initialState,
    on(getDiscount, (state, payload) => {
        return null;
    }),  
    on(getDiscountSuccess, (state, payload) => {
        return payload;
    }),
    on(getDiscountFailed, (state, payload) => {
        return false;
    }),
    on(fullPayFive, (state, payload) => {
        return 'fullpay';
    }),
    on(reset, (state, payload) => {
        return 'reset';
    }),
    on(loadInitState, (state, payload) => {
        return null
    })
);

export function discountReducer(state, action) {
    return _discountReducer(state, action);
}
