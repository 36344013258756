import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mat-modal',
  templateUrl: './mat-dialog-modal.component.html',
  styleUrls: ['./mat-dialog-modal.component.scss']
})
export class MatDialogModalComponent {

  modal: any;
  showError: boolean = false;

  constructor(public dialogRef: MatDialogRef<MatDialogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.modal = data;
  }
  
  closeWithYes() {
    if (this.modal.accept && this.modal.accept === 'yes') {
    this.dialogRef.close(true);
    } else {
      this.showError = true;
    }
  }
  closeWithNo() {
    if (this.modal && this.modal.accept === 'no') {
      this.dialogRef.close(true);
    } else {
      this.showError = true;
    }
  }
  close() {
    this.dialogRef.close();
  }
}