import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit, OnDestroy {

  @Input('vehicleObject') public vehicle: any;
  @Input() public component: any;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.slickInit(event)
  }

  slickConfig = {};
  currentSlide = -1 // set at -1 to initialize
  currentTimeout = 6000; 
  defaultTimeout = 6000; // default
  slider: any;

  slickInit(e) {
  const lookForVV = setInterval(() => {
    const verifyVehicle = document.querySelector(".app-component-vehicle") as HTMLElement;
    if (verifyVehicle) {
      verifyVehicle.style.position = 'absolute'
      verifyVehicle.style.top = '100px'
      verifyVehicle.style.left = '60px'
      clearInterval(lookForVV)
      }
    }, 1)
    setTimeout(() => {
      if (lookForVV) {
        clearInterval(lookForVV) // Turn this off after 5 seconds in case VV doesn't exist
      }
    }, 5000);
  }

  breakpoint(e) {
    console.log('breakpoint');
  }
  afterChange(e) {
    console.log('afterChange');
  }
  beforeChange(e) {
    console.log('beforeChange:', e.currentSlide);
    this.currentSlide = e.currentSlide;
  }

  nextSlide() {
    clearTimeout(this.slider);
    this.currentSlide++;
    if(this.currentSlide == this.component.fields.length) {
      this.currentSlide = 0;
    }
    this.currentTimeout = this.component.fields[this.currentSlide].seconds_per_slide ? this.component.fields[this.currentSlide].seconds_per_slide * 1000 : this.defaultTimeout;
    console.log('slide ',this.currentSlide)
    console.log('timeout ',this.currentTimeout)

    this.slider = setTimeout(() => {
      this.slickModal.slickNext();
      this.nextSlide()
    }, this.currentTimeout)
  }

  constructor(private prismicService: PrismicService) { }

  ngOnInit() {
    this.slickConfig = {
      autoplay: false, 
      dots: false, 
      arrows: false, 
      infinite: true, 
      // autoplaySpeed: 6000, // Default 6 seconds per slide
      speed: 1000, 
      slidesToShow: 1, 
      slidesToScroll: 1
    };
    this.currentTimeout = this.component.fields[0].seconds_per_slide ? this.component.fields[0].seconds_per_slide * 1000 : this.defaultTimeout;
    console.log('timeout ',this.currentTimeout)
    if (this.component.fields && this.component.fields.length > 1) {
      this.nextSlide();
    }
  }

  ngOnDestroy(): void {
    if (this.slider) {
      clearTimeout(this.slider);
    }
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }
}
