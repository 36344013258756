import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ReplaySubject, Subscription } from 'rxjs';
import { Vehicle } from '../_models/vehicle';
import imageCompression from 'browser-image-compression';
import brandInfo from "src/environments/brand.json";
import { s3UploadDocuments, s3UploadSuccess } from '../_actions/s3-management.action';
import { select, Store } from '@ngrx/store';



@Component({
  selector: 'app-modal-vehicle-inspection',
  templateUrl: './modal-vehicle-inspection.component.html',
  styleUrls: ['./modal-vehicle-inspection.component.scss']
})
export class ModalVehicleInspectionComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() content: string;
  vin: string;
  sub: Subscription
  brandInfo: any;

  public files: NgxFileDropEntry[] = [];
  documents = [];
  newDocuments = [];

  constructor(public modalRef: MDBModalRef, private router: Router, private store: Store<{
    s3Management: any
  }>) { }

  ngOnInit(): void {
    this.brandInfo = brandInfo.brand;
    let vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    this.vin = vehicle.vin;
    this.sub = this.router.events.subscribe((event) => {
      this.modalRef.hide()
    })
    this.store.pipe(select('s3Management')).subscribe((state) => {
      if (state && state.success) {
        let items = sessionStorage.getItem('inspectionDocs') ? JSON.parse(sessionStorage.getItem('inspectionDocs')) : [];
        console.log('success: ', state)
        state.data.forEach(file => {
          console.log('file: ', file.fileKey)
          items.push({ 'fileKey': file.fileKey, 'date': this.getDate(file.fileKey), 'name': this.getName(file.fileKey) })
        })
        window.sessionStorage.setItem('inspectionDocs', JSON.stringify(items));
        //this.router.navigate(['/documents']);
        window.location.href = '/documents';
      }
    });
  }

  getDate(fileKey) {
    return (fileKey.split('/')[1]).split('-')[0];
  }

  getName(fileKey) {
    return (fileKey.split('/')[1]);
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
    window.scrollTo(0, 0);
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  public dropped(files: NgxFileDropEntry[]) {
    files.forEach(file => {
      if (file.fileEntry.isFile &&
        (file.fileEntry.name.toLowerCase().indexOf(".png") > -1 ||
          file.fileEntry.name.toLowerCase().indexOf(".pdf") > -1 ||
          file.fileEntry.name.toLowerCase().indexOf(".jpg") > -1 ||
          file.fileEntry.name.toLowerCase().indexOf(".jfif") > -1 ||
          file.fileEntry.name.toLowerCase().indexOf(".jpeg") > -1)) {
        this.files.push(file)
      }
    })

    //  this.files = files;
    this.documents = [];
    for (const droppedFile of this.files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          const reader = new FileReader();

          console.log('document stuff', file.name, file.type)
          if (file.type.toLowerCase().indexOf("pdf") > -1) {
            // Upload PDFs raw
            reader.readAsDataURL(file);
          } else if (file.type.toLowerCase().indexOf("png") > -1 ||
            file.type.toLowerCase().indexOf("jpg") > -1 ||
            file.type.toLowerCase().indexOf("jpeg") > -1 ||
            file.type.toLowerCase().indexOf("jfif") > -1) {

            // Compress images for better storage management
            const compressedFile = this.compressImage(file)

            compressedFile.then(file => {
              reader.readAsDataURL(file)
            })
          } else {
            alert('Some file(s) did not upload.')
          }

          reader.onloadend = (e) => {
            const ranNum = Math.floor(Math.random() * 10000)
            this.documents.push({ data: reader.result, date: file.lastModified, name: this.vin + '-' + ranNum + '-' + file.name, type: file.type });
            console.log('documents: ', this.documents)
            console.log('reader: ', reader)
          }

        });
      }
    }
  }

  async compressImage(file) {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    const image = await imageCompression(file, options)
    return image;
  }

  _handleReaderLoaded(e) {
    console.log("_handleReaderLoaded")
    var reader = e.target;
    const imageSrc = reader.result;
  }

  uploadFiles(modal) {
    modal.hide();

    try {
      const fileToUpload: File[] = this.documents;
      this.store.dispatch(s3UploadDocuments({ brandName: brandInfo.brand, fileToUpload: fileToUpload, vin: this.vin }));
      //this.router.navigate(['/documents']);
      //window.location.href = '/documents';
    } catch (error) {
      alert('Document file size(s) exceeds limit.')
    }

  }
}
