<div class="modal-content">
  <div class="modal-header mb-n1">
    <span aria-hidden="true"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close"
        (click)="close()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <h3 class="mb-4">{{ data.title }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center mb-2">
        {{ data.message }}
      </div>
    </div>
    <div class="row text-center">
      <div class="col-12 text-center mt-1 mb-1" style="display: flex;">
        <button class="btn secondary-btn modal-button" mdbBtn mdbWavesEffect
          (click)="closeWithYes();">{{ 'RATES_PAGE.YES' | translate }}</button>
        <button class="btn secondary-btn modal-button" mdbBtn mdbWavesEffect
          (click)="closeWithNo();">{{ 'RATES_PAGE.NO' | translate }}</button>
      </div>

    </div>
    <div class="row text-center" *ngIf="data.error && showError">
      <div class="col-12 mt-1 mb-1 error">
        {{ data.error }}
      </div>
    </div>
  </div>
</div>