<!-- CART MODAL START -->
<!-- <app-navbar-cart #cartModal="cartModal"></app-navbar-cart> -->
<!-- CART MODAL END -->

<!-- PHONE MODAL START -->
<app-phone-modal #phoneModalView="phoneModal" [phoneNumber]='branding.cs_phone_number'></app-phone-modal>
<!-- PHONE MODAL END -->

<!--Double navigation-->
<header *ngIf="!isWidget">

  <!-- Nav -->
  <app-desktop-nav [logo]="logo" [navLinks]="navLinks" [isLogin]="isLogin" (showCart)="showCart()" (callbackSignOut)="clickAccountLogout()" *ngIf="isNavVisible"></app-desktop-nav>
  <!--/. Nav -->

</header>
<div class="icon-bar">
  <div class="desktop">
    <a href="javascript:void(0)" class="call" role="button" tabindex="0" [attr.aria-label]="'NAV.CALL_US' | translate" (click)="phoneModalView.show()"><mdb-icon fas icon="phone"></mdb-icon></a>
  </div>
  <div class="mobile" >
    <a href="tel:{{ branding.cs_phone_number }}" class="call" [attr.aria-label]="'NAV.CALL_US' | translate">
      <mdb-icon fas icon="phone"></mdb-icon>
    </a>
  </div>
</div>

<!--/.Double navigation-->
