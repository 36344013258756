import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Observable } from 'rxjs';
import { SelectedPlan } from 'src/app/_models/selectedPlan';
import { PrismicService } from 'src/app/_services/prismic.service';
import * as SelectedPlanActions from '../../states/planSelect/selectedPlan.actions';
import brandInfo from '../../../environments/brand.json'
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ModalInfoComponent } from 'src/app/modal-info/modal-info.component';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogModalComponent } from 'src/app/mat-dialog-modal/mat-dialog-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-your-plan',
  templateUrl: './select-your-plan.component.html',
  styleUrls: ['./select-your-plan.component.scss']
})
export class SelectYourPlanComponent implements OnInit, AfterViewInit {
  @Input() title: any;
  @Input() subTitle: any;
  @Input() plans: any;
  @Input() chosenPlan: any;
  @Input() _deductible: string;
  @Input() select_your_plan_title: any;
  @Input() select_your_plan_subhead: any;
  @Output() emitAddToCart = new EventEmitter<string>();
  @Output() marketingPlan = new EventEmitter<any>();

  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  slickConfig = {};
  plansToDisplay$: Observable<any>;
  plansToDisplay: any;
  preferredLanguage: string;
  currentSlide = 2 // set at -1 to initialize
  currentTimeout = 6000;
  defaultTimeout = 6000; // default
  currentSelectedIndex = 2; // Set default selected index
  paymentPlanCount = 0;
  noFee = '';
  multiDeductible: boolean;
  brand: string;
  paypal: boolean;
  deductible: string;
  isWAState: boolean;
  fullPayOnly: boolean;
  allowDiscounts: boolean;
  isFLState: boolean;
  modalRef: MDBModalRef;

  constructor(private translate: TranslateService,private dialog: MatDialog,private prismicService: PrismicService, private modalService: MDBModalService,
    private store: Store<{ selectedPlan: any; }>) { }

  ngOnInit(): void {
    this.brand = brandInfo.brand;
    this.paypal = brandInfo.paypal;
    this.isWAState = JSON.parse(sessionStorage.getItem('vehicle')).registeredState === 'WA';
    this.isFLState = JSON.parse(sessionStorage.getItem('vehicle')).registeredState === 'FL';
    this.fullPayOnly = brandInfo.fullPayOnly;
    this.allowDiscounts = brandInfo.allowDiscounts && !this.isFLState && !this.isWAState

    this.slickConfig = {
      autoplay: false,
      dots: false,
      arrows: true,
      infinite: false,
      speed: 1000,
      swipe: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    this.store.dispatch(SelectedPlanActions.loadSelectedPlan({ defaultPlan: this.plans[this.currentSelectedIndex] }));
    this.plansToDisplay$ = this.store.select((store) => store.selectedPlan);
    this.plansToDisplay$.subscribe(val => {
      this.plansToDisplay = val.allPlansPlanBasedTerm;
      this.deductible = val.currentSelectedPlan ? val.currentSelectedPlan.term.deductible : '';
      console.log('VALLLLLLLLL 11', val);
      this.paymentPlanCount = val.currentSelectedPlan ? val.currentSelectedPlan.term.numberOfPayments : 1;
    });
    this.selectPlan(this.plansToDisplay.length - 1);

    this.preferredLanguage = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
    this.multiDeductible = brandInfo.multiDeductible;

    if (brandInfo.brand === 'NCESI') {
      if (this.preferredLanguage.indexOf('fr') > -1) {
        this.noFee = 'pas de frais';
      } else {
        this.noFee = 'no fee';
      }
    }
  }

  breakpoint(e) {
    console.log('breakpoint');
  }
  afterChange(e) {
    console.log('afterChange');
  }
  beforeChange(e) {
    console.log('beforeChange:', e.currentSlide);
    this.currentSlide = e.currentSlide;
  }

  nextSlide() {
    this.currentSlide++;
    if (this.currentSlide == this.plans.length) {
      this.currentSlide = 0;
    }
    this.currentTimeout = this.defaultTimeout;
    console.log('slide ', this.currentSlide)
    console.log('timeout ', this.currentTimeout)
  }

  ngAfterViewInit(): void {
    // Go to the third slide after the view has initialized
    this.slickModal.slickGoTo(2);
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

  selectPlan(index: number): void {
    let newPlan = this.plans[index] as SelectedPlan;
    this.chosenPlan = newPlan;
    console.log('chosenPlan: ', this.chosenPlan)
    this.currentSelectedIndex = index; // Update the selected index
    // this.marketingPlan.emit(this.marketingPlan);
    this.store.dispatch(SelectedPlanActions.loadSelectedPlan({ defaultPlan: this.plans[index] }));
  }


  addToCart() {
    console.log("chosenPlan", this.chosenPlan);
    if (this.brand === 'Porsche' && this.chosenPlan.product.productCode === 'POSD') { // Look for the product code !!UPDATE!!
      const title = this.translate.instant('MODAL.TIRE_THREAD_TITLE');
      const content = this.translate.instant('MODAL.TIRE_THREAD_CONTENT');
      const error = this.translate.instant('MODAL.TIRE_ERROR');
      this.showModal(title, content, error, 'yes');
    } else if (this.brand === 'Porsche') { // If the product code is not found assume other product
      const title = this.translate.instant('MODAL.CPO_ELIGIBILITY');
      const content = this.translate.instant('MODAL.CPO_CONTENT');
      const error = this.translate.instant('MODAL.CPO_ERROR');
      this.showModal(title, content, error, 'no');
    } else { // Else send the addToCart event
      this.emitAddToCart.emit("addToCart");
    }
  }

  showModal(title: String, content: String, error: String, accept: String) {
    const dialogRef = this.dialog.open(MatDialogModalComponent, {
      width: '400px',
      panelClass: 'modal-md',
      closeOnNavigation: true,
      disableClose: true,
      data: {
        message: content,
        title: title,
        accept: accept,
        error: error
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.emitAddToCart.emit("addToCart");
      }
    });
  }
}
